import ApiService from '@/services/ApiService'
import type ApiResponse from '@/responses/ApiResponse'
import type Team from '@/models/Team'
import UserService from '@/services/UserService'

export default class TeamService {
  public static acceptInvite(teamId: string): Promise<void> {
    return ApiService.post<ApiResponse<void>>(
      `team/${teamId}/accept-invite`
    ).then(() => {
      UserService.updateUserInfo()
      return
    })
  }

  public static cancelInvite(id: string, email: string): Promise<void> {
    return ApiService.delete<ApiResponse<void>>(
      `team/${id}/invites/${email}`
    ).then(() => {
      return
    })
  }

  public static checkInvite(teamId: string): Promise<void> {
    return ApiService.get<ApiResponse<void>>(
      `team/${teamId}/check-invite`
    ).then(() => {
      return
    })
  }

  public static createOrUpdateTeam(
    id: string | undefined,
    name: string,
    monthly_limit: number
  ): Promise<Team | undefined> {
    const url = id ? `team/${id}` : 'team'
    return ApiService.request<ApiResponse<Team>>(id ? 'PUT' : 'POST', url, {
      name,
      monthly_limit,
    }).then((result) => {
      UserService.updateUserInfo()
      return result.data
    })
  }

  public static getTeam(id: string): Promise<Team | undefined> {
    return ApiService.get<ApiResponse<Team>>(`team/${id}`).then((result) => {
      return result.data
    })
  }

  public static inviteUser(
    id: string,
    email: string,
    role: string,
    monthly_limit: number
  ): Promise<void> {
    return ApiService.post<ApiResponse<void>>(`team/${id}/users/invite`, {
      email,
      monthly_limit,
      role,
    }).then(() => {
      return
    })
  }

  public static updateUser(
    id: string,
    userId: string,
    role: string,
    monthly_limit: number
  ): Promise<void> {
    return ApiService.put<ApiResponse<void>>(`team/${id}/users/${userId}`, {
      monthly_limit,
      role,
    }).then(() => {
      return
    })
  }

  public static removeUser(id: string, userId: string): Promise<void> {
    return ApiService.delete<ApiResponse<void>>(
      `team/${id}/users/${userId}`
    ).then(() => {
      return
    })
  }
}
