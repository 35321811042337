import { createI18n } from 'vue-i18n'
import en from './en.json'

import { useUserStore } from '@/stores/UserStore'
import { storeToRefs } from 'pinia'
import { watch } from 'vue'
import Config from '@/utils/config'

const messages: Record<string, any> = { en }

const loadLanguage = async (language: string) => {
  if (messages[language]) {
    return
  }

  let res = null
  switch (language) {
    case 'en':
      res = en
      break
    case 'de':
      res = await import('./de.json')
      break
    default:
      res = en
      break
  }

  messages[language] = res
}

const i18n = (
  url?: string,
  languageCookie?: string,
  acceptLanguage?: string
) => {
  const userStore = useUserStore()
  const { language } = storeToRefs(userStore)
  if (import.meta.env.SSR) {
    let urlLang = undefined
    if (url) {
      const [_, languageCode] = url.split('/')
      if (Config.languages.includes(languageCode)) {
        urlLang = languageCode
      }
    }

    let acceptLang = undefined
    if (acceptLanguage) {
      const acceptLanguages = acceptLanguage.split(',')
      for (const lang of acceptLanguages) {
        const [languageCode] = lang.split('-')
        if (Config.languages.includes(languageCode)) {
          acceptLang = languageCode
          break
        }
      }
    }

    if (urlLang) {
      if (Config.languages.includes(urlLang)) {
        language.value = urlLang
      }
    } else if (languageCookie) {
      if (Config.languages.includes(languageCookie)) {
        language.value = languageCookie
      }
    } else if (acceptLang) {
      if (Config.languages.includes(acceptLang)) {
        language.value = acceptLang
      }
    }

    userStore.setLanguage(language.value)
  }

  const vuei18n = createI18n({
    legacy: false,
    locale: 'en',
    globalInjection: false,
    fallbackLocale: 'en',
    messages,
  })

  watch(
    () => language.value,
    async (newLanguage) => {
      await loadLanguage(newLanguage)

      vuei18n.global.setLocaleMessage(newLanguage, messages[newLanguage])
      if (newLanguage == language.value) {
        // because of async import newLanguage could be outdated
        /** @ts-ignore */
        vuei18n.global.locale.value = newLanguage
      }
    },
    { immediate: true }
  )

  vuei18n.global.warnHtmlMessage = false
  return vuei18n
}

export { i18n, loadLanguage }
