import type { Router } from 'vue-router'
import { useUserStore } from '@/stores/UserStore'
import UserService from '@/services/UserService'

export const useAuthGuard = (router: Router) => {
  router.beforeEach((to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      next()
      return
    }

    const userStore = useUserStore()
    if (!userStore.isLoggedIn()) {
      const redirect = to.fullPath
      next({ name: 'login', query: { redirect } })
      return
    }

    if (!userStore.userInfo) {
      UserService.updateUserInfo()
    }

    next()
  })
}
