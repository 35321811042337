<template>
  <h2 class="text-center">{{ t('dashboard.teams.users.new_user') }}</h2>
  <n-form
    ref="formRef"
    label-placement="left"
    label-width="auto"
    :model="model"
    :rules="rules"
    size="large"
  >
    <n-form-item :label="t('dashboard.teams.users.email')" path="email">
      <n-input v-model:value="model.email" />
    </n-form-item>
    <n-form-item :label="t('dashboard.teams.users.role')" path="role">
      <n-radio-group v-model:value="model.role">
        <n-radio value="admin">{{ t('dashboard.teams.role.admin') }}</n-radio>
        <n-radio value="user">{{ t('dashboard.teams.role.user') }}</n-radio>
      </n-radio-group>
    </n-form-item>
    <n-form-item
      :label="t('dashboard.teams.users.monthly_limit')"
      path="monthly_limit"
    >
      <n-input-number v-model:value="model.monthly_limit" />
    </n-form-item>
    <n-form-item label=" " class="mt-4">
      <n-button type="primary" @click="inviteUser" :disabled="loading">
        <n-spin v-if="loading" size="small" stroke="white" />
        <span v-if="!loading">{{ t('dashboard.teams.users.invite') }}</span>
      </n-button>
      <n-button @click="emit('cancel')" :disabled="loading">
        {{ t('common.cancel') }}
      </n-button>
    </n-form-item>
    <p v-if="errorMessage" class="text-red-500">
      {{ errorMessage }}
    </p>
  </n-form>
</template>

<script setup lang="ts">
import {
  NButton,
  NForm,
  NFormItem,
  NInput,
  NInputNumber,
  NRadio,
  NRadioGroup,
  NSpin,
} from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import TeamService from '@/services/TeamService'
import { isEmail } from '@/utils/is-email'
import type Team from '@/models/Team'

const { t } = useI18n()
const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)

const props = defineProps<{
  team: Team
}>()

const emit = defineEmits(['cancel', 'invited'])

const model = ref({
  email: '',
  monthly_limit: 0,
  role: 'user',
})

const rules: FormRules = {
  email: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.teams.users.emailRequired'))
      } else if (!isEmail(value)) {
        return new Error(t('dashboard.teams.users.emailInvalid'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  monthly_limit: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (isNaN(Number(value))) {
        return new Error(t('dashboard.teams.users.monthly_limitRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  role: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.teams.users.roleRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

const inviteUser = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      TeamService.inviteUser(
        props.team.id,
        model.value.email,
        model.value.role,
        model.value.monthly_limit
      )
        .then(() => {
          loading.value = false
          emit('invited')
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
    })
    .catch(() => {})
}
</script>
