<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true" class="p-4 md:p-8">
      <h1 class="text-center">{{ t('dashboard.teams.users.title') }}</h1>
      <n-data-table
        :columns="columns"
        :data="data"
        :pagination="false"
        :bordered="false"
      />

      <div class="my-4">
        <n-button
          v-if="!inviteUser && !userTeam"
          type="primary"
          @click="inviteUser = true"
        >
          {{ t('dashboard.teams.users.invite') }}
        </n-button>
        <InviteUser
          v-if="inviteUser && !userTeam && team"
          :team="team"
          @cancel="inviteUser = false"
          @invited="
            () => {
              inviteUser = false
              getTeam()
            }
          "
        />
        <EditUser
          v-if="userTeam"
          :userTeam="userTeam"
          @cancel="userTeam = undefined"
          @updated="
            () => {
              userTeam = undefined
              getTeam()
            }
          "
        />
      </div>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import EditUser from '@/views/dashboard/EditUser.vue'
import InviteUser from '@/views/dashboard/InviteUser.vue'
import TeamService from '@/services/TeamService'
import type Team from '@/models/Team'
import type TeamInvite from '@/models/TeamInvite'
import type UserTeam from '@/models/UserTeam'
import type { DataTableColumns } from 'naive-ui'
import { NButton, NCard, NDataTable } from 'naive-ui'
import { computed, h, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

type ItemType = (UserTeam | TeamInvite) & { type: string }

const { t } = useI18n()
const inviteUser = ref(false)
const route = useRoute()
const userTeam = ref<UserTeam | undefined>(undefined)
const team = ref<Team | undefined>(undefined)

const createColumns = ({
  edit,
  remove,
}: {
  edit: (userTeam: ItemType) => void
  remove: (userTeam: ItemType) => void
}): DataTableColumns<ItemType> => {
  return [
    {
      title: t('dashboard.teams.users.name'),
      key: 'user.name',
      render(userTeam) {
        return h(
          'span',
          {
            class: userTeam.type == 'invite' ? 'italic opacity-60' : '',
          },
          userTeam.type == 'invite'
            ? t('dashboard.teams.users.invited')
            : (userTeam as UserTeam).user.name
        )
      },
    },
    {
      title: t('dashboard.teams.users.email'),
      key: 'user.email',
      render(userTeam) {
        return userTeam.type == 'invite'
          ? (userTeam as TeamInvite).email
          : (userTeam as UserTeam).user.email
      },
    },
    {
      title: t('dashboard.teams.users.role'),
      key: 'role',
      render(userTeam) {
        return t(`dashboard.teams.role.${userTeam.role}`)
      },
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render(userTeam) {
        const buttons: any[] = []
        if (userTeam.type == 'user') {
          buttons.push(
            h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: 'small',
                onClick: () => edit(userTeam),
              },
              { default: () => t('common.edit') }
            )
          )
        }
        buttons.push(
          h(
            NButton,
            {
              disabled:
                userTeam.type == 'user' &&
                userTeam.role == 'owner' &&
                team.value?.user_team.filter((ut) => ut.role == 'owner')
                  .length == 1,
              strong: true,
              tertiary: true,
              size: 'small',
              onClick: () => remove(userTeam),
            },
            { default: () => t('common.remove') }
          )
        )

        return h(
          'div',
          {
            class: 'flex flex-col md:flex-row gap-2',
          },
          buttons
        )
      },
    },
  ]
}

const columns = createColumns({
  edit(editUserTeam: ItemType) {
    inviteUser.value = false
    userTeam.value = editUserTeam as UserTeam
  },
  remove(item: ItemType) {
    if (item.type === 'user') {
      TeamService.removeUser(item.team_id, (item as UserTeam).user_id).then(
        () => {
          getTeam()
        }
      )
    } else {
      TeamService.cancelInvite(team.value!.id, (item as TeamInvite).email).then(
        () => {
          getTeam()
        }
      )
    }
  },
})

const data = computed(() => {
  const users = team.value?.user_team.map((ut) => {
    return {
      ...ut,
      type: 'user',
    }
  })
  const invites = team.value?.team_invites.map((ti) => {
    return {
      ...ti,
      type: 'invite',
    }
  })
  return [...(users || []), ...(invites || [])]
})

const teamId = computed(() => {
  return route.params.id
})

const getTeam = () => {
  if (!teamId.value) {
    team.value = undefined
    return
  }
  TeamService.getTeam(teamId.value as string)
    .then((result) => {
      team.value = result
    })
    .catch(() => {})
}

watch(
  teamId,
  () => {
    getTeam()
  },
  { immediate: true }
)
</script>
