import Echo from 'laravel-echo'
import Pusher from 'pusher-js'
import { emit } from '@/main'
import io from 'socket.io-client'
import type TaskProgress from '@/events/TaskProgress'
import { useUserStore } from '@/stores/UserStore'
import { watch } from 'vue'
import ApiService from '@/services/ApiService'
import type Conversation from '@/models/Conversation'
import type Folder from '@/models/Folder'
import { fixDateFields } from '@/utils/fix-date-fields'

export default class WebsocketService {
  protected static status: 'initial' | 'connecting' | 'connected' = 'initial'
  protected static echo?: Echo

  public static init() {
    this.connect()
    const userStore = useUserStore()

    watch(
      () => userStore.userInfo?.user_id,
      (newValue, oldValue) => {
        if (oldValue) {
          this.stopListenUser(oldValue)
        }
        if (newValue) {
          this.listenUser(newValue)
        }
      },
      { immediate: true }
    )

    watch(
      () => userStore.teamId,
      (newValue, oldValue) => {
        if (oldValue) {
          this.stopListenTeam(oldValue)
        }
        if (newValue) {
          this.listenTeam(newValue)
        }
      },
      { immediate: true }
    )
  }

  protected static connect() {
    if (this.status != 'initial') {
      this.disconnect()
    }
    this.status = 'connecting'

    // @ts-ignore-next-line
    window.io = io

    // @ts-ignore-next-line
    window.Pusher = Pusher

    try {
      this.echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_SOCKET_KEY,
        wsHost: import.meta.env.VITE_SOCKET_HOST,
        wsPort: import.meta.env.VITE_SOCKET_PORT,
        wssPort: import.meta.env.VITE_SOCKET_PORT,
        authEndpoint: import.meta.env.VITE_SOCKET_AUTH_ENDPOINT,
        auth: {
          headers: ApiService.getAuthHeader(),
        },
        forceTLS: import.meta.env.VITE_SOCKET_TLS == 'true',
        disableStats: true,
        enabledTransports: ['ws'],
        cluster: '',
      })
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log('Websocket initialization error', e)
    }

    this.status = 'connected'
  }

  public static disconnect() {
    this.echo?.disconnect()
    this.echo = undefined
    this.status = 'initial'
  }

  protected static listenTeam(teamId: string) {
    if (this.echo == undefined) return
    this.echo
      .private(`App.Models.Team.${teamId}`)
      .listen(
        'Conversations\\ConversationCreated',
        (o: { conversation: Conversation }) => {
          fixDateFields(o)
          emit.emit('ConversationCreated', o.conversation)
        }
      )
      .listen(
        'Conversations\\ConversationUpdated',
        (o: { conversation: Conversation }) => {
          fixDateFields(o)
          emit.emit('ConversationUpdated', o.conversation)
        }
      )
      .listen(
        'Conversations\\ConversationDeleted',
        (o: { conversation_id: string }) => {
          emit.emit('ConversationDeleted', o.conversation_id)
        }
      )
      .listen('Folders\\FolderCreated', (o: { folder: Folder }) => {
        fixDateFields(o)
        emit.emit('FolderCreated', o.folder)
      })
      .listen('Folders\\FolderUpdated', (o: { folder: Folder }) => {
        fixDateFields(o)
        emit.emit('FolderUpdated', o.folder)
      })
      .listen('Folders\\FolderDeleted', (o: { folder_id: string }) => {
        emit.emit('FolderDeleted', o.folder_id)
      })
      .error((err: string) => {
        // eslint-disable-next-line no-console
        console.log('Websocket error', err)
      })
  }

  protected static listenUser(userId: string) {
    if (this.echo == undefined) return
    this.echo
      .private(`App.Models.User.${userId}`)
      .listen(
        'Conversations\\ConversationCreated',
        (o: { conversation: Conversation }) => {
          fixDateFields(o)
          emit.emit('ConversationCreated', o.conversation)
        }
      )
      .listen(
        'Conversations\\ConversationUpdated',
        (o: { conversation: Conversation }) => {
          fixDateFields(o)
          emit.emit('ConversationUpdated', o.conversation)
        }
      )
      .listen(
        'Conversations\\ConversationDeleted',
        (o: { conversation_id: string }) => {
          emit.emit('ConversationDeleted', o.conversation_id)
        }
      )
      .listen('Folders\\FolderCreated', (o: { folder: Folder }) => {
        fixDateFields(o)
        emit.emit('FolderCreated', o.folder)
      })
      .listen('Folders\\FolderUpdated', (o: { folder: Folder }) => {
        fixDateFields(o)
        emit.emit('FolderUpdated', o.folder)
      })
      .listen('Folders\\FolderDeleted', (o: { folder_id: string }) => {
        emit.emit('FolderDeleted', o.folder_id)
      })
      .listen('Tasks\\TaskProgress', (o: TaskProgress) => {
        emit.emit('TaskProgress', o)
      })
      .error((err: string) => {
        // eslint-disable-next-line no-console
        console.log('Websocket error', err)
      })
  }

  protected static stopListenTeam(teamId: string) {
    if (this.echo == undefined) return
    this.echo.leave(`App.Models.Team.${teamId}`)
  }

  protected static stopListenUser(userId: string) {
    if (this.echo == undefined) return
    this.echo.leave(`App.Models.User.${userId}`)
  }

  public static getStatus(): string {
    return this.status
  }
}
