import type Folder from '@/models/Folder'
import ApiService from '@/services/ApiService'
import type ApiResponse from '@/responses/ApiResponse'

export default class FolderService {
  public static createFolder(
    owner: string,
    type: string,
    parent_id: string | null,
    name?: string
  ): Promise<Folder | undefined> {
    return ApiService.post<ApiResponse<Folder>>('folder', {
      name,
      owner,
      parent_id,
      type,
    }).then((response) => response.data)
  }

  public static deleteFolder(folderId: string): Promise<void> {
    return ApiService.delete(`folder/${folderId}`)
  }

  public static getFolders(): Promise<Folder[] | undefined> {
    return ApiService.get<ApiResponse<Folder[]>>('folder').then(
      (response) => response.data
    )
  }

  public static moveFolder(
    folderId: string,
    parent_id: string
  ): Promise<Folder | undefined> {
    return ApiService.put<ApiResponse<Folder>>(`folder/${folderId}/move`, {
      parent_id,
    }).then((response) => response.data)
  }

  public static renameFolder(
    folderId: string,
    name: string
  ): Promise<Folder | undefined> {
    return ApiService.put<ApiResponse<Folder>>(`folder/${folderId}/rename`, {
      name,
    }).then((response) => response.data)
  }
}
