<template>
  <div
    class="absolute md:relative md:block"
    :class="{ hidden: !showMobile, block: showMobile }"
  >
    <n-layout has-sider class="h-screen">
      <n-layout-sider
        ref="sider"
        class="h-screen"
        collapse-mode="width"
        :collapsed-width="64"
        :collapsed="collapsed && !showMobile"
        @collapse="collapsed = true"
        @expand="collapsed = false"
        @mouseenter="mouseEnter"
        @mouseleave="mouseLeave"
      >
        <div class="block md:hidden">
          <n-icon
            class="text-[36px] m-6 cursor-pointer"
            @click="emit('close-click')"
          >
            <Close />
          </n-icon>
        </div>
        <div class="flex justify-center p-4 mb-6 items-center h-[115px]">
          <RouterLink :to="{ name: 'home' }" @click="emit('close-click')">
            <img class="w-full max-h-[84px]" src="/favicon.gif" />
          </RouterLink>
        </div>
        <n-menu
          v-model:value="activeKey"
          :collapsed="collapsed && !showMobile"
          :collapsed-width="64"
          :icon-size="20"
          :collapsed-icon-size="24"
          :options="options"
          @click="emit('close-click')"
        />
      </n-layout-sider>
    </n-layout>
  </div>
</template>

<script setup lang="ts">
import { computed, h, ref } from 'vue'
import type { VNode } from 'vue'
import { NIcon, NLayout, NLayoutSider, NMenu } from 'naive-ui'
import type { MenuOption } from 'naive-ui'
import Background from '@/assets/icons/Background.svg'
import BookGlobe from '@/assets/icons/BookGlobe.svg'
import BookQuestionMark from '@/assets/icons/BookQuestionMark.svg'
import Chat from '@/assets/icons/Chat.svg'
import Clipboard from '@/assets/icons/Clipboard.svg'
import Close from '@/assets/icons/Close.svg'
import DocumentSearch from '@/assets/icons/DocumentSearch.svg'
import Home from '@/assets/icons/Home.svg'
import Image from '@/assets/icons/Image.svg'
import ImageToImage from '@/assets/icons/ImageToImage.svg'
import User from '@/assets/icons/User.svg'
import Team from '@/assets/icons/Team.svg'
import Voice from '@/assets/icons/Voice.svg'
import Zoom from '@/assets/icons/Zoom.svg'
import { RouterLink, useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { onClickOutside } from '@vueuse/core'
import { useUserStore } from '@/stores/UserStore'

const { t } = useI18n()

defineProps<{
  showMobile: boolean
}>()

const collapsed = ref(true)
const mouseIn = ref(false)
const route = useRoute()
const sider = ref(null)
const userStore = useUserStore()

onClickOutside(sider, () => emit('close-click'))

const activeKey = computed((): string | null => {
  if (route.name) {
    return route.name.toString().replace('info.', '')
  }
  return null
})

const renderLabel = (label: string, to: any): VNode => {
  return h(
    RouterLink,
    {
      to: to,
    },
    { default: () => label }
  )
}

const renderIcon = (icon: string): VNode => {
  return h(NIcon, {}, { default: () => h(icon) })
}

const dashboardMenuOptions = computed((): MenuOption[] => [
  {
    label: () => renderLabel(t('menu.home'), { name: 'home' }),
    key: 'home',
    icon: () => renderIcon(Home),
  },
  {
    label: () =>
      renderLabel(t('menu.my-profile'), { name: 'dashboard.profile' }),
    key: 'profile',
    icon: () => renderIcon(User),
  },
  {
    label: () => renderLabel(t('menu.my-teams'), { name: 'dashboard.teams' }),
    key: 'teams',
    icon: () => renderIcon(Team),
  },
])

const menuOptions = computed((): MenuOption[] => [
  {
    label: () =>
      renderLabel(t('sidebar.chat'), {
        name: userStore.isLoggedIn() ? 'chat' : 'info.text.chat',
      }),
    key: 'chat',
    icon: () => renderIcon(Chat),
  },
  {
    label: () =>
      renderLabel(t('sidebar.talkTo'), {
        name: userStore.isLoggedIn() ? 'talkTo' : 'info.text.talkTo',
      }),
    key: 'talkTo',
    icon: () => renderIcon(DocumentSearch),
  },
  {
    label: () =>
      renderLabel(t('sidebar.text'), {
        name: userStore.isLoggedIn() ? 'textTool' : 'info.text.tool',
      }),
    key: 'textTool',
    icon: () => renderIcon(Clipboard),
  },
  {
    label: () =>
      renderLabel(t('sidebar.translator'), {
        name: userStore.isLoggedIn() ? 'translator' : 'info.text.translator',
      }),
    key: 'translator',
    icon: () => renderIcon(BookGlobe),
  },
  {
    label: () =>
      renderLabel(t('sidebar.summarizer'), {
        name: userStore.isLoggedIn() ? 'summarizer' : 'info.text.summarizer',
      }),
    key: 'summarizer',
    icon: () => renderIcon(BookQuestionMark),
  },
  {
    label: () =>
      renderLabel(t('sidebar.image'), {
        name: userStore.isLoggedIn()
          ? 'imageGenerator'
          : 'info.image.generator',
      }),
    key: 'imageGenerator',
    icon: () => renderIcon(Image),
  },
  {
    label: () =>
      renderLabel(t('sidebar.image-to-image'), {
        name: userStore.isLoggedIn()
          ? 'image-to-image'
          : 'info.image.image-to-image',
      }),
    key: 'image-to-image',
    icon: () => renderIcon(ImageToImage),
  },
  {
    label: () =>
      renderLabel(t('sidebar.image-background'), {
        name: userStore.isLoggedIn()
          ? 'image-background'
          : 'info.image.image-background',
      }),
    key: 'image-background',
    icon: () => renderIcon(Background),
  },
  {
    label: () =>
      renderLabel(t('sidebar.upscaler'), {
        name: userStore.isLoggedIn() ? 'upscaler' : 'info.image.upscaler',
      }),
    key: 'upscaler',
    icon: () => renderIcon(Zoom),
  },
  {
    label: () =>
      renderLabel(t('sidebar.voice'), {
        name: userStore.isLoggedIn()
          ? 'voiceGenerator'
          : 'info.audio.voiceGenerator',
      }),
    key: 'voiceGenerator',
    icon: () => renderIcon(Voice),
  },
])

const emit = defineEmits(['close-click'])

const mouseEnter = () => {
  mouseIn.value = true
  collapsed.value = false
}

const mouseLeave = () => {
  mouseIn.value = false
  setTimeout(() => {
    if (!mouseIn.value) {
      collapsed.value = true
    }
  }, 200)
}

const options = computed(() => {
  if (
    route.name &&
    typeof route.name === 'string' &&
    route.name.startsWith('dashboard.')
  ) {
    return dashboardMenuOptions.value
  }
  return menuOptions.value
})
</script>
