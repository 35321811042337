<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true" class="p-4 md:p-8">
      <h1 class="text-center">
        {{
          teamId
            ? t('dashboard.teams.edit.title_edit')
            : t('dashboard.teams.edit.title_new')
        }}
      </h1>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
      >
        <n-form-item :label="t('dashboard.teams.edit.name')" path="name">
          <n-input v-model:value="model.name" />
        </n-form-item>
        <n-form-item
          :label="t('dashboard.teams.edit.monthly_limit')"
          path="monthly_limit"
        >
          <n-input-number v-model:value="model.monthly_limit" />
        </n-form-item>
        <n-form-item label=" " class="mt-4">
          <n-button
            type="primary"
            @click="createOrUpdateTeam"
            :disabled="loading"
          >
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{
              t(teamId ? 'common.save' : 'common.create')
            }}</span>
          </n-button>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import {
  NButton,
  NCard,
  NForm,
  NFormItem,
  NInput,
  NInputNumber,
  NSpin,
} from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import TeamService from '@/services/TeamService'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)
const router = useRouter()
const route = useRoute()

const model = ref({
  monthly_limit: 0,
  name: '',
})

const rules: FormRules = {
  monthly_limit: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (isNaN(Number(value))) {
        return new Error(t('dashboard.teams.edit.monthly_limitRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  name: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.teams.edit.nameRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

const createOrUpdateTeam = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      TeamService.createOrUpdateTeam(
        teamId.value,
        model.value.name,
        model.value.monthly_limit
      )
        .then(() => {
          loading.value = false
          router.push('/dashboard/teams')
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
    })
    .catch(() => {})
}

const teamId = computed(() => route.params.id as string)

watch(
  () => teamId,
  (value) => {
    model.value = {
      name: '',
      monthly_limit: 0,
    }
    if (value.value) {
      TeamService.getTeam(value.value)
        .then((team) => {
          if (team) {
            model.value = {
              name: team.name,
              monthly_limit: team.monthly_limit,
            }
          }
        })
        .catch(() => {})
    }
  },
  { immediate: true }
)
</script>
