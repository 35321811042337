import type { Router } from 'vue-router'
import UserService from '@/services/UserService'

export const usePhoneGuard = (router: Router) => {
  router.beforeEach(async (to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresVerifiedPhone)) {
      next()
      return
    }

    const userInfo = await UserService.updateUserInfo()
    if (
      userInfo &&
      (!userInfo.is_phone_verification_required || userInfo.phone_verified_at)
    ) {
      next()
      return
    }

    const redirect = to.fullPath
    if (userInfo?.phone) {
      next({ name: 'verifyPhone', query: { redirect } })
      return
    } else {
      next({ name: 'savePhone', query: { redirect } })
      return
    }
  })
}
