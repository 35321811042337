<template>
  <h2 class="text-center">{{ t('dashboard.teams.users.edit_user') }}</h2>
  <n-form
    ref="formRef"
    label-placement="left"
    label-width="auto"
    :model="model"
    :rules="rules"
    size="large"
  >
    <n-form-item :label="t('dashboard.teams.users.role')" path="role">
      <n-radio-group v-model:value="model.role">
        <n-radio value="admin">{{ t('dashboard.teams.role.admin') }}</n-radio>
        <n-radio value="user">{{ t('dashboard.teams.role.user') }}</n-radio>
      </n-radio-group>
    </n-form-item>
    <n-form-item
      :label="t('dashboard.teams.users.monthly_limit')"
      path="monthly_limit"
    >
      <n-input-number v-model:value="model.monthly_limit" />
    </n-form-item>
    <n-form-item label=" " class="mt-4">
      <n-button type="primary" @click="updateUser" :disabled="loading">
        <n-spin v-if="loading" size="small" stroke="white" />
        <span v-if="!loading">{{ t('common.save') }}</span>
      </n-button>
      <n-button @click="emit('cancel')" :disabled="loading">
        {{ t('common.cancel') }}
      </n-button>
    </n-form-item>
    <p v-if="errorMessage" class="text-red-500">
      {{ errorMessage }}
    </p>
  </n-form>
</template>

<script setup lang="ts">
import {
  NButton,
  NCard,
  NForm,
  NFormItem,
  NInputNumber,
  NRadio,
  NRadioGroup,
  NSpin,
} from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import TeamService from '@/services/TeamService'
import type UserTeam from '@/models/UserTeam'

const { t } = useI18n()
const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)

const props = defineProps<{
  userTeam: UserTeam
}>()

const emit = defineEmits(['cancel', 'updated'])

const model = ref({
  monthly_limit: 0,
  role: 'user',
})

const rules: FormRules = {
  monthly_limit: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (isNaN(Number(value))) {
        return new Error(t('dashboard.teams.users.monthly_limitRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  role: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.teams.users.roleRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

const updateUser = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      TeamService.updateUser(
        props.userTeam.team_id,
        props.userTeam.user_id,
        model.value.role,
        model.value.monthly_limit
      )
        .then(() => {
          loading.value = false
          emit('updated')
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
    })
    .catch(() => {})
}

watch(
  () => props.userTeam,
  (userTeam) => {
    if (userTeam) {
      model.value = {
        monthly_limit: userTeam.monthly_limit,
        role: userTeam.role == 'owner' ? 'admin' : userTeam.role,
      }
    }
  },
  { immediate: true }
)
</script>
