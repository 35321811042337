import axios from 'axios'
import { createSSRApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

import App from './App.vue'
import VueGtag from 'vue-gtag'
import { createRouter } from './router'

import './assets/main.css'
import { i18n } from '@/i18n'
import { useAuthGuard } from '@/guards/AuthGuard'
import { usePhoneGuard } from '@/guards/PhoneGuard'

// @ts-ignore
import VueTelInput from 'vue3-tel-input'
import 'vue3-tel-input/dist/vue3-tel-input.css'
import { initializeSentry } from '@/sentry'
import mitt from 'mitt'
import type { Router } from 'vue-router'

let router: Router

export const emit = mitt()

export function createApp(request?: any) {
  const app = createSSRApp(App)
  router = createRouter()

  initializeSentry(app, router)

  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  useAuthGuard(router)
  usePhoneGuard(router)

  app.use(pinia)
  app.use(router)
  const acceptLanguage = request?.headers['accept-language']
  const languageCookie = request?.cookies?.language
  app.use(i18n(request?.url, languageCookie, acceptLanguage))
  app.use(VueTelInput)

  addGlobalAxiosInterceptor()

  if (import.meta.env.VITE_GOOGLE_ANALYTICS_TAG) {
    app.use(VueGtag, {
      config: { id: import.meta.env.VITE_GOOGLE_ANALYTICS_TAG },
    })
  }

  // prevent Tailwind overriding Naive UI styles
  if (!import.meta.env.SSR) {
    const meta = document.createElement('meta')
    meta.name = 'naive-ui-style'
    document.head.appendChild(meta)
  }

  return { app, router }
}

const addGlobalAxiosInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return Promise.resolve(response)
    },
    async (error) => {
      if (error.response.status === 401) {
        emit.emit('unauthorized')
        return Promise.reject(error)
      }
      if (error.response.status === 402) {
        emit.emit('insufficientCredits')
        return Promise.reject(error)
      }
      return Promise.reject(error)
    }
  )
}
