import {
  computed,
  createCommentVNode,
  defineComponent,
  h,
  type PropType,
} from 'vue'
import type { MetaDataType } from '@/models/MetaData'
import Config from '@/utils/config'

export const MetaTags = defineComponent({
  name: 'MetaTags',
  props: {
    metaDataFn: {
      type: Function as PropType<() => MetaDataType>,
      required: true,
    },
    ssr: { type: Boolean, default: false },
  },
  setup(props) {
    const removeLanguageSegment = (url: string): string => {
      const urlObject = new URL(url)
      const path = urlObject.pathname
      const segments = path.split('/')
      const languages = Config.languages
      if (segments.length > 1 && languages.includes(segments[1])) {
        segments.splice(1, 1)
      }
      urlObject.pathname = segments.join('/')
      let newUrl = urlObject.toString()
      if (newUrl.endsWith('/')) {
        newUrl = newUrl.slice(0, -1)
      }
      return newUrl
    }

    const setLanguageSegment = (url: string, language: string): string => {
      const urlObject = new URL(url)
      const path = urlObject.pathname
      const segments = path.split('/')
      const languages = Config.languages
      if (segments.length > 1 && languages.includes(segments[1])) {
        segments[1] = language
      } else {
        segments.splice(1, 0, language)
      }
      urlObject.pathname = segments.join('/')
      let newUrl = urlObject.toString()
      if (newUrl.endsWith('/')) {
        newUrl = newUrl.slice(0, -1)
      }
      return newUrl
    }

    const title = computed(() => props.metaDataFn().title?.value)
    const description = computed(() => props.metaDataFn().description?.value)
    const image = computed(() => props.metaDataFn().image?.value)
    const url = computed(() => props.metaDataFn().url?.value)
    const noIndex = computed(() => props.metaDataFn().noIndex?.value)
    const language = computed(() => props.metaDataFn().language?.value)
    const canonical = computed(() => props.metaDataFn().canonical?.value)

    return () => {
      const headers = []
      headers.push(createCommentVNode('meta-tags-start'))

      if (props.ssr || !import.meta.env.SSR) {
        if (title?.value) {
          headers.push(h('title', {}, title.value))
          headers.push(
            h('meta', { property: 'og:title', content: title.value })
          )
          headers.push(
            h('meta', { property: 'twitter:title', content: title.value })
          )
        }
        if (description?.value) {
          headers.push(
            h('meta', { name: 'description', content: description.value })
          )
          headers.push(
            h('meta', {
              property: 'og:description',
              content: description.value,
            })
          )
          headers.push(
            h('meta', {
              property: 'twitter:description',
              content: description.value,
            })
          )
        }
        if (image?.value) {
          headers.push(
            h('meta', { property: 'og:image', content: image.value })
          )
          headers.push(
            h('meta', { property: 'twitter:image', content: image.value })
          )
        }
        if (url?.value) {
          headers.push(h('meta', { property: 'og:url', content: url.value }))
          headers.push(
            h('meta', { property: 'twitter:url', content: url.value })
          )
        }
        if (noIndex?.value) {
          headers.push(h('meta', { name: 'robots', content: 'noindex' }))
        }

        if (language?.value) {
          headers.push(
            h('meta', {
              'http-equiv': 'content-language',
              content: language.value,
            })
          )
        }

        if (url?.value) {
          for (const lang of Config.languages) {
            const langUrl = setLanguageSegment(url?.value, lang)
            headers.push(
              h('link', {
                rel: 'alternate',
                hreflang: lang,
                href: langUrl,
              })
            )
          }

          const defaultUrl = removeLanguageSegment(url?.value)
          headers.push(
            h('link', {
              rel: 'alternate',
              hreflang: 'x-default',
              href: defaultUrl,
            })
          )
        }

        if (language?.value && url?.value) {
          headers.push(
            h('link', {
              rel: 'canonical',
              href: canonical?.value
                ? canonical?.value
                : setLanguageSegment(url?.value, language.value),
            })
          )
        }
      }

      headers.push(createCommentVNode('meta-tags-end'))
      return headers
    }
  },
})
