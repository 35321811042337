import ApiService from '@/services/ApiService'
import { useUserStore } from '@/stores/UserStore'
import type ApiResponse from '@/responses/ApiResponse'
import type LoginResponse from '@/responses/LoginResponse'
import type RegisterResponse from '@/responses/RegisterResponse'
import type UserInfo from '@/models/UserInfo'
import type RedeemCouponResponse from '@/responses/RedeemCouponResponse'

export default class UserService {
  public static updateUserInfo(): Promise<UserInfo | undefined> {
    const userStore = useUserStore()
    if (!userStore.isLoggedIn()) {
      return Promise.resolve(undefined)
    }
    return ApiService.get<ApiResponse<UserInfo>>('user/info').then(
      (response) => {
        userStore.userInfo = response.data
        if (!userStore.teamId) {
          const team = response.data?.teams[0]
          if (team) {
            userStore.teamId = team.id
          }
        }
        return response.data
      }
    )
  }

  public static login(email: string, password: string) {
    const userStore = useUserStore()
    return ApiService.post<ApiResponse<LoginResponse>>('login', {
      d: userStore.getDeviceId(),
      email,
      password,
    }).then((response) => {
      userStore.token = response.data?.token
      userStore.teamId = undefined
      userStore.userInfo = undefined
      this.updateUserInfo()
    })
  }

  public static logout(): void {
    const userStore = useUserStore()
    ApiService.post('logout').catch(() => {})
    userStore.token = undefined
    userStore.teamId = undefined
    userStore.userInfo = undefined
  }

  public static register(
    name: string,
    email: string,
    password: string
  ): Promise<void> {
    const userStore = useUserStore()
    return ApiService.post<ApiResponse<RegisterResponse>>('register', {
      d: userStore.getDeviceId(),
      name,
      email,
      password,
    }).then((response) => {
      userStore.token = response.data?.token
      userStore.teamId = undefined
      userStore.userInfo = undefined
      this.updateUserInfo()
    })
  }

  public static redeemCoupon(
    code: string
  ): Promise<RedeemCouponResponse | undefined> {
    return ApiService.post<ApiResponse<RedeemCouponResponse>>(
      'user/redeem-coupon',
      { code }
    ).then((response) => {
      this.updateUserInfo()
      return response.data
    })
  }

  public static resendCode() {
    return ApiService.post('user/resend-code')
  }

  public static resetPassword(email: string) {
    return ApiService.post('reset-password', { email })
  }

  public static savePhone(phone: string) {
    return ApiService.put('user/save-phone', { phone })
  }

  public static setPassword(email: string, password: string, token: string) {
    const userStore = useUserStore()
    return ApiService.post<ApiResponse<LoginResponse>>('set-password', {
      d: userStore.getDeviceId(),
      email,
      password,
      token,
    }).then((response) => {
      userStore.token = response.data?.token
      userStore.teamId = undefined
      userStore.userInfo = undefined
      this.updateUserInfo()
    })
  }

  public static updateProfile(name: string, email: string): Promise<void> {
    const userStore = useUserStore()
    return ApiService.put<ApiResponse<RegisterResponse>>('profile', {
      name,
      email,
    }).then((response) => {
      userStore.userInfo = undefined
      this.updateUserInfo()
    })
  }

  public static verifyPhone(code: string) {
    return ApiService.put('user/verify-phone', { code })
  }

  public static waitlist(model: any) {
    return ApiService.post('waitlist', model)
  }
}
