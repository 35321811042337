<template>
  <n-collapse-item :title="title" :name="owner + '-' + type">
    <template v-if="canEditFolders || type == 'history'" #header-extra>
      <n-icon
        v-if="type == 'history'"
        class="mx-1.5 text-red-500"
        @click.stop="emit('clearHistory')"
        :title="t('library.clearHistory')"
      >
        <Delete />
      </n-icon>
      <n-icon
        v-if="canEditFolders"
        class="mx-1.5 text-ci-primary"
        @click.stop="emit('createFolder', owner, type, null)"
        :title="t('library.createFolder')"
      >
        <Plus />
      </n-icon>
    </template>

    <n-tree
      v-model:selectedKeys="selectedKeys"
      class="pl-5"
      :data="data"
      block-line
      expand-on-click
      :render-prefix="renderPrefix"
      :render-suffix="renderSuffix"
      :node-props="({ option }) => ({ onClick: () => itemClick(option) })"
    />
  </n-collapse-item>
</template>

<script setup lang="ts">
import Plus from '@/assets/icons/Plus.svg'
import { NCollapseItem, NIcon, NTree, type TreeOption } from 'naive-ui'
import { h, ref, watch } from 'vue'
import Delete from '@/assets/icons/Delete.svg'
import Conversation from '@/assets/icons/Conversation.svg'
import Folder from '@/assets/icons/Folder.svg'
import TemplateIcon from '@/assets/icons/Template.svg'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'

const route = useRoute()
const selectedKeys = ref<string[]>([])
const { t } = useI18n()

const props = defineProps<{
  canEditConversations?: boolean
  canEditFolders?: boolean
  data: TreeOption[]
  owner: string
  title: string
  type: string
}>()

const emit = defineEmits<{
  (
    e: 'createFolder',
    owner: string,
    type: string,
    parentId: string | null
  ): void
  (e: 'clearHistory'): void
  (e: 'deleteConversation', id: string, name: string): void
  (e: 'deleteFolder', id: string, name: string): void
  (e: 'openConversation', id: string): void
}>()

const itemClick = (option: TreeOption) => {
  const key = option.key as string
  const type = option['type']
  if (type !== 'conversation') {
    return
  }
  if (key) {
    emit('openConversation', key)
  }
}

const renderPrefix = ({ option }: { option: TreeOption }) => {
  if (option['type'] == 'folder') {
    return h(NIcon, { title: t('library.folder') }, { default: () => h(Folder) })
  }
  if (option['type'] == 'conversation' && option.is_template) {
    return h(NIcon, { title: t('library.template') }, { default: () => h(TemplateIcon) })
  }
  if (option['type'] == 'conversation' && !option.is_template) {
    return h(NIcon, { title: t('library.conversation') }, { default: () => h(Conversation) })
  }
}

const renderSuffix = ({ option }: { option: TreeOption }) => {
  const actions = []

  if (option['type'] == 'folder') {
    if (props.canEditFolders) {
      actions.push(
        h(
          NIcon,
          {
            onClick: (e: Event) => {
              e.stopPropagation()
              emit('deleteFolder', option.key as string, option.label as string)
            },
            class: 'text-red-500',
            title: t('library.deleteFolder'),
          },
          {
            default: () => h(Delete),
          }
        )
      )

      actions.push(
        h(
          NIcon,
          {
            onClick: (e: Event) => {
              e.stopPropagation()
              emit(
                'createFolder',
                props.owner,
                props.type,
                option.key as string
              )
            },
            class: 'text-ci-primary',
            title: t('library.createFolder'),
          },
          {
            default: () => h(Plus),
          }
        )
      )
    }
  }

  if (option['type'] == 'conversation') {
    if (props.canEditConversations) {
      actions.push(
        h(
          NIcon,
          {
            onClick: (e: Event) => {
              e.stopPropagation()
              emit(
                'deleteConversation',
                option.key as string,
                option.label as string
              )
            },
            class: 'text-red-500',
            title: t('library.deleteConversation'),
          },
          {
            default: () => h(Delete),
          }
        )
      )
    }
  }

  return h('div', { class: 'flex items-center gap-3' }, actions)
}

watch(
  () => route.params.conversationId,
  (value) => {
    selectedKeys.value = value ? [value as string] : []
  },
  { immediate: true }
)
</script>
