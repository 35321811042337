<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true">
      <h1 class="text-center">{{ t('start.title') }}</h1>
      <p>{{ t('start.intro') }}</p>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
        class="mt-4"
      >
        <n-form-item :label="t('start.name')" path="name">
          <n-input ref="nameRef" v-model:value="model.name" />
        </n-form-item>
        <n-form-item :label="t('start.email')" path="email">
          <n-input v-model:value="model.email" />
        </n-form-item>
        <n-form-item :label="t('start.password')" path="password">
          <n-input
            minlength="8"
            type="password"
            v-model:value="model.password"
          />
        </n-form-item>
        <n-form-item label=" " class="mt-4">
          <n-button type="primary" @click="register" :disabled="loading">
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{ t('start.register') }}</span>
          </n-button>
          <RouterLink
            :to="{ name: 'login', query: { redirect: route.query.redirect } }"
            class="ml-8"
          >
            {{ t('start.login') }}
          </RouterLink>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { NButton, NCard, NForm, NFormItem, NInput, NSpin } from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { event } from 'vue-gtag'
import { isEmail } from '@/utils/is-email'
import UserService from '@/services/UserService'
import { useRoute, useRouter } from 'vue-router'
import { useMeta } from '@/composables/Meta'

const { t } = useI18n()
const { updateMetaData } = useMeta()

updateMetaData({
  title: computed(() => t('start.seoTitle')),
  description: computed(() => t('start.seoDescription')),
})

const router = useRouter()
const route = useRoute()

const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)
const nameRef = ref<HTMLElement | null>(null)

const model = ref({
  email: '',
  name: '',
  password: '',
})

const rules: FormRules = {
  name: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('start.nameRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  email: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('start.emailRequired'))
      } else if (!isEmail(value)) {
        return new Error(t('start.emailInvalid'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  password: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('start.passwordRequired'))
      } else if (value.length < 8) {
        return new Error(t('start.passwordTooShort'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

onBeforeMount(() => {
  if (import.meta.env.VITE_ALLOW_REGISTRATION !== 'true') {
    router.push({ name: 'waitlist' })
  }
})

onMounted(() => {
  nameRef.value?.focus()
})

const register = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      UserService.register(
        model.value.name,
        model.value.email,
        model.value.password
      )
        .then(() => {
          event('signup')
          const redirect = route.query.redirect as string
          router.push({ name: 'start-confirm', query: { redirect } })
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
    })
    .catch(() => {})
}
</script>
