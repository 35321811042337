<template>
  <div class="flex flex-col">
    <div class="pl-4 pr-4 pt-4 md:pl-16 md:pr-16 md:pt-16">
      <n-card :embedded="true">
        <h1 class="text-center">{{ t('buyCredits.title') }}</h1>
        <p>{{ t('buyCredits.intro') }}</p>

        <p>
          <n-icon class="mb-[-4px]" color="green" :size="18">
            <CheckOutlined />
          </n-icon>
          {{ t('buyCredits.advantage-1') }}
        </p>
        <p>
          <n-icon class="mb-[-4px]" color="green" :size="18">
            <CheckOutlined />
          </n-icon>
          {{ t('buyCredits.advantage-2') }}
        </p>
        <p>
          <n-icon class="mb-[-4px]" color="green" :size="18">
            <CheckOutlined />
          </n-icon>
          {{ t('buyCredits.advantage-3') }}
        </p>
        <p>
          <n-icon class="mb-[-4px]" color="green" :size="18">
            <CheckOutlined />
          </n-icon>
          {{ t('buyCredits.advantage-4') }}
        </p>
        <p>
          <n-icon class="mb-[-4px]" color="green" :size="18">
            <CheckOutlined />
          </n-icon>
          {{ t('buyCredits.advantage-5') }}
        </p>
      </n-card>
    </div>

    <div class="flex flex-col xl:flex-row gap-4 pl-4 pr-4 md:pl-16 md:pr-16 pt-4">
      <n-card :embedded="true" class="flex-grow">
        <div class="flex flex-row">
          <div class="flex-grow">
            <h2>{{ t('buyCredits.package-1.title') }}</h2>
            <h4>{{ t('buyCredits.package-1.subtitle') }}</h4>
            <p class="font-bold">{{ t('buyCredits.package-1.credits') }}</p>
            <p class="font-bold text-red-500">
              {{ t('buyCredits.package-1.free-credits') }}
            </p>
            <RouterLink
              :to="
                userStore.isLoggedIn()
                  ? { name: 'payment', params: { package: 'package-1' } }
                  : { name: 'start' }
              "
            >
              <n-button type="primary">
                {{
                  t('buyCredits.package-1.price')
                }}
              </n-button>
            </RouterLink>
          </div>
          <div class="w-1/3 p-4 flex justify-center">
            <div>
              <img src="@/assets/images/packages/1.webp" />
            </div>
          </div>
        </div>
      </n-card>
      <n-card :embedded="true" class="flex-grow">
        <div class="flex flex-row">
          <div class="flex-grow">
            <h2>{{ t('buyCredits.package-2.title') }}</h2>
            <h4>{{ t('buyCredits.package-2.subtitle') }}</h4>
            <p class="font-bold">{{ t('buyCredits.package-2.credits') }}</p>
            <p class="font-bold text-red-500">
              {{ t('buyCredits.package-2.free-credits') }}
            </p>
            <RouterLink
              :to="
                userStore.isLoggedIn()
                  ? { name: 'payment', params: { package: 'package-2' } }
                  : { name: 'start' }
              "
            >
              <n-button type="primary">
                {{
                  t('buyCredits.package-2.price')
                }}
              </n-button>
            </RouterLink>
            <span class="pl-4"><i>{{ t('buyCredits.mostPopular') }}</i></span>
          </div>
          <div class="w-1/3 p-4 flex justify-center">
            <div>
              <img src="@/assets/images/packages/2.webp" />
            </div>
          </div>
        </div>
      </n-card>
      <n-card :embedded="true" class="flex-grow">
        <div class="flex flex-row">
          <div class="flex-grow">
            <h2>{{ t('buyCredits.package-3.title') }}</h2>
            <h4>{{ t('buyCredits.package-3.subtitle') }}</h4>
            <p class="font-bold">{{ t('buyCredits.package-3.credits') }}</p>
            <p class="font-bold text-red-500">
              {{ t('buyCredits.package-3.free-credits') }}
            </p>
            <RouterLink
              :to="
                userStore.isLoggedIn()
                  ? { name: 'payment', params: { package: 'package-3' } }
                  : { name: 'start' }
              "
            >
              <n-button type="primary">
                {{
                  t('buyCredits.package-3.price')
                }}
              </n-button>
            </RouterLink>
          </div>
          <div class="w-1/3 p-4 flex justify-center">
            <div>
              <img src="@/assets/images/packages/3.webp" />
            </div>
          </div>
        </div>
      </n-card>
    </div>

    <div class="pl-4 pr-4 md:pl-16 md:pr-16 pt-4 pb-4 md:pb-16">
      <n-card :embedded="true">
        <h1 class="text-center">{{ t('buyCredits.pricing.title') }}</h1>

        <h2>{{ t('buyCredits.pricing.tool-1.title') }}</h2>
        <p>{{ t('buyCredits.pricing.tool-1.credits') }}</p>
        <h2>{{ t('buyCredits.pricing.tool-2.title') }}</h2>
        <p v-html="t('buyCredits.pricing.tool-2.credits')" />
        <h2>{{ t('buyCredits.pricing.tool-3.title') }}</h2>
        <p>{{ t('buyCredits.pricing.tool-3.credits') }}</p>
      </n-card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { NButton, NCard, NIcon } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/stores/UserStore'
import CheckOutlined from '@/assets/icons/CheckOutlined.svg'
import { useMeta } from '@/composables/Meta'
import { computed } from 'vue'

const { t } = useI18n()
const userStore = useUserStore()
const { updateMetaData } = useMeta()

updateMetaData({
  title: computed(() => t('buyCredits.seoTitle')),
  description: computed(() => t('buyCredits.seoDescription')),
})
</script>
