<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true">
      <h1 class="text-center">{{ t('resetPassword.title') }}</h1>
      <p class="mb-8">{{ t('resetPassword.text') }}</p>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
      >
        <n-form-item :label="t('resetPassword.password')" path="password">
          <n-input
            ref="passwordRef"
            minlength="8"
            type="password"
            v-model:value="model.password"
          />
        </n-form-item>
        <n-form-item label=" " class="mt-4">
          <n-button type="primary" @click="resetPassword" :disabled="loading">
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{ t('resetPassword.submit') }}</span>
          </n-button>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import { NButton, NCard, NForm, NFormItem, NInput, NSpin } from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import UserService from '@/services/UserService'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)
const passwordRef = ref<HTMLInputElement | null>(null)

const model = ref({
  password: '',
})

const rules: FormRules = {
  password: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('resetPassword.passwordRequired'))
      } else if (value.length < 8) {
        return new Error(t('resetPassword.passwordTooShort'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

onMounted(() => {
  passwordRef.value?.focus()
})

const resetPassword = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      await UserService.setPassword(
        email.value,
        model.value.password,
        token.value
      )
        .then(() => {
          router.push({ name: 'home' })
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}

const email = computed(() => route.query.email as string)
const token = computed(() => route.query.token as string)
</script>
