<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true" class="p-4 md:p-8">
      <h1 class="text-center">{{ t('dashboard.profile.title') }}</h1>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
      >
        <n-form-item :label="t('dashboard.profile.name')" path="name">
          <n-input v-model:value="model.name" />
        </n-form-item>
        <n-form-item :label="t('dashboard.profile.email')" path="email">
          <n-input v-model:value="model.email" />
        </n-form-item>
        <n-form-item label=" " class="mt-4">
          <n-button type="primary" @click="updateProfile" :disabled="loading">
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{ t('dashboard.profile.update') }}</span>
          </n-button>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NButton, NCard, NForm, NFormItem, NInput, NSpin } from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { ref, watch } from 'vue'
import { isEmail } from '@/utils/is-email'
import UserService from '@/services/UserService'
import { useUserStore } from '@/stores/UserStore'

const { t } = useI18n()
const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)
const userStore = useUserStore()

const model = ref({
  email: '',
  name: '',
})

const rules: FormRules = {
  email: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.profile.emailRequired'))
      } else if (!isEmail(value)) {
        return new Error(t('dashboard.profile.emailInvalid'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  name: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('dashboard.profile.nameRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

const updateProfile = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      UserService.updateProfile(model.value.name, model.value.email)
        .then(() => {
          loading.value = false
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('common.failed')
          loading.value = false
        })
    })
    .catch(() => {})
}

watch(
  () => userStore.userInfo,
  (userInfo) => {
    model.value = {
      email: userInfo?.email ?? '',
      name: userInfo?.name ?? '',
    }
  },
  { immediate: true }
)
</script>
