<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true" class="p-4 md:p-8">
      <h1 class="text-center">{{ t('dashboard.teams.title') }}</h1>
      <n-data-table
        :columns="columns"
        :data="userStore.userInfo?.teams"
        :pagination="false"
        :bordered="false"
      />
      <div class="my-4">
        <n-button type="primary" @click="newTeam">
          {{ t('dashboard.teams.new') }}
        </n-button>
      </div>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NButton, NCard, NDataTable } from 'naive-ui'
import type { DataTableColumns } from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { h } from 'vue'
import type Team from '@/models/Team'
import type UserTeam from '@/models/UserTeam'
import { useUserStore } from '@/stores/UserStore'
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const userStore = useUserStore()

const createColumns = ({
  edit,
  users,
}: {
  edit: (team: Team) => void
  users: (team: Team) => void
}): DataTableColumns<Team & Partial<UserTeam>> => {
  return [
    {
      title: t('dashboard.teams.name'),
      key: 'name',
    },
    {
      title: t('dashboard.teams.credit_balance'),
      key: 'credit_balance',
    },
    {
      title: t('common.actions'),
      key: 'actions',
      render(team) {
        if (team.role != 'owner' && team.role != 'admin') {
          return null
        }

        return h(
          'div',
          {
            class: 'flex flex-col md:flex-row gap-2',
          },
          [
            h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: 'small',
                onClick: () => edit(team),
              },
              { default: () => t('dashboard.teams.action_edit') }
            ),
            h(
              NButton,
              {
                strong: true,
                tertiary: true,
                size: 'small',
                onClick: () => users(team),
              },
              { default: () => t('dashboard.teams.action_users') }
            ),
          ]
        )
      },
    },
  ]
}

const columns = createColumns({
  edit(team: Team) {
    router.push({ name: 'dashboard.teams.edit', params: { id: team.id } })
  },
  users(team: Team) {
    router.push({ name: 'dashboard.teams.users', params: { id: team.id } })
  },
})

const newTeam = () => {
  router.push({ name: 'dashboard.teams.new' })
}
</script>
