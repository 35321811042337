import ApiService from '@/services/ApiService'
import type ApiResponse from '@/responses/ApiResponse'
import type Content from '@/models/Content'

export default class ContentService {
  public static getContents(tags: string[] = []): Promise<Content[]> {
    return ApiService.get<ApiResponse<{ contents: Content[] }>>('content', {
      tags,
    }).then((response) => response.data?.contents ?? [])
  }

  public static getContent(key: string): Promise<Content | null> {
    return ApiService.get<
      ApiResponse<{ content: Content; relatedContents: Content[] }>
    >(`content/${key}`).then(
      (response) => response.data?.content ?? null,
      () => null
    )
  }
}
