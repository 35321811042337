<template>
  <teleport to="head">
    <MetaTags :meta-data-fn="metaDataFn" />
    <ConfigState />
  </teleport>
  <n-config-provider
    v-if="!disableSSR"
    :theme="isDarkMode ? darkTheme : lightTheme"
    :theme-overrides="themeOverrides"
    :locale="locale"
    :date-locale="dateDeDE"
  >
    <n-dialog-provider>
      <n-global-style :css-vars="cssVars" />
      <n-message-provider>
        <div class="fixed h-full w-full flex flex-row">
          <Sidebar
            :showMobile="showMobileSidebar"
            @close-click="showMobileSidebar = false"
            class="flex-shrink-0"
          />
          <div
            id="main-wrapper"
            class="flex flex-col min-h-screen overflow-y-scroll w-full"
          >
            <Header @menu-click="showMobileSidebar = true" />
            <div class="flex-grow flex flex-row">
              <RouterView class="flex-grow" />
              <div
                v-if="showLibrary"
                class="w-0 lg:w-[320px] max-w-0 lg:max-w-[320px] min-w-0 lg:min-w-[240px] shrink-0 grow-0 relative"
              >
                <div class="absolute h-full">
                  <Library />
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </n-message-provider>
    </n-dialog-provider>
  </n-config-provider>
</template>

<script setup lang="ts">
import {
  NConfigProvider,
  NDialogProvider,
  NGlobalStyle,
  NMessageProvider,
  createLocale,
  deDE,
  dateDeDE,
  type GlobalThemeOverrides,
  darkTheme,
  lightTheme,
} from 'naive-ui'
import { ConfigState } from '@/components/ConfigState'
import { MetaTags } from '@/components/MetaTags'
import Footer from '@/components/Footer.vue'
import Header from '@/components/Header.vue'
import Sidebar from '@/components/Sidebar.vue'
import { computed, onMounted, onServerPrefetch, ref, watch } from 'vue'
import { useMeta } from '@/composables/Meta'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import UserService from '@/services/UserService'
import WebsocketService from '@/services/WebsocketService'
import { useUserStore } from '@/stores/UserStore'
import Library from '@/components/library/Library.vue'
// app instance
import { getCurrentInstance } from 'vue'

const app = getCurrentInstance()
const fullUrl = app?.appContext.config.globalProperties.fullUrl
const locale = createLocale(
  {
    Input: {
      placeholder: '',
    },
  },
  deDE
)

const { t } = useI18n()

const { metaDataFn, updateMetaData } = useMeta()
const userStore = useUserStore()

const cssVars = {
  fontFamily: 'Montserrat, sans-serif',
}

const disableSSR = computed(() => {
  if (!import.meta.env.SSR) return false
  const disable = route.matched.some((record) => {
    return record.meta.ssr != undefined && record.meta.ssr === false
  })
  return disable
})

const isDarkMode = ref(false)

onMounted(() => {
  if (
    import.meta.env.SSR
      ? false
      : window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
  ) {
    isDarkMode.value = true
  }

  UserService.updateUserInfo()

  WebsocketService.init()
})

const resetMetaData = () => {
  return updateMetaData({
    language: computed(() => userStore.language),
    title: computed(() => t('common.seo.title')),
    description: computed(() => t('common.seo.description')),
    image: computed(() => t('common.seo.image')),
    noIndex: computed(() => false),
    url: computed(() => (import.meta.env.SSR ? fullUrl : window.location.href)),
    next: computed(() => undefined),
    prev: computed(() => undefined),
  })
}

resetMetaData()

const route = useRoute()

const language = computed((): string => {
  return (route.params.locale as string) ?? userStore.language ?? 'de'
})

const themeOverrides: GlobalThemeOverrides = {
  common: {
    fontFamily: 'Montserrat, sans-serif',
  },
}

const showLibrary = computed(() => {
  return route.matched.some((record) => {
    return record.meta.showLibrary
  })
})

const showMobileSidebar = ref(false)

watch(
  () => route.path,
  () => {
    showMobileSidebar.value = false
    resetMetaData()
  }
)

onServerPrefetch(() => {
  if (!language.value) return
  userStore.setLanguage(language.value)
})

watch(
  () => language.value,
  (newLanguage) => {
    if (!newLanguage) return
    userStore.setLanguage(newLanguage)
  },
  { immediate: true }
)

watch(
  () => userStore.language,
  (newLanguage) => {
    if (!newLanguage) return
    updateMetaData({
      language: computed(() => userStore.language),
    })
  },
  { immediate: true }
)
</script>
