import { format, parse } from 'date-fns'

export class Time {
  public static timeRegex = /^\d\d:\d\d(:\d\d)?$/

  protected value: Date

  constructor(timeString?: string) {
    if (!timeString) timeString = '00:00:00'
    if (!Time.timeRegex.test(timeString)) {
      throw Error('Invalid time string')
    }
    this.value = Time.toDate(timeString)
  }

  public get date(): Date {
    return this.value
  }

  public set date(value: Date) {
    this.value = value
  }

  public static toDate(timeString: string): Date {
    const formatString = timeString.length == 5 ? 'HH:mm' : 'HH:mm:ss'
    return parse(timeString, formatString, new Date())
  }

  public static fromDate(date: Date): string {
    return format(date, 'HH:mm:ss')
  }

  public static isBetween(date: Date, fromTime: Time, toTime: Time) {
    const fromDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      fromTime.date.getHours(),
      fromTime.date.getMinutes(),
      fromTime.date.getSeconds()
    )
    const toDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      toTime.date.getHours(),
      toTime.date.getMinutes(),
      toTime.date.getSeconds()
    )
    return date >= fromDate && date <= toDate
  }

  // return time with today's date for comparisons
  public valueOf(): number {
    const today = new Date()
    return new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      this.value.getHours(),
      this.value.getMinutes(),
      this.value.getSeconds()
    ).valueOf()
  }

  public toJSON(): string {
    return Time.fromDate(this.value)
  }
}
