<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true">
      <h1 class="text-center">{{ t('savePhone.title') }}</h1>
      <p class="mb-8">{{ t('savePhone.text') }}</p>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
      >
        <n-form-item :label="t('savePhone.phone')" path="phone">
          <vue-tel-input v-model="model.phone" ref="phone" />
        </n-form-item>
        <n-form-item label=" " class="mt-4">
          <n-button type="primary" @click="savePhone" :disabled="loading">
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{ t('savePhone.submit') }}</span>
          </n-button>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ref } from 'vue'
import { NButton, NCard, NForm, NFormItem, NSpin } from 'naive-ui'
import type { FormInst, FormRules } from 'naive-ui'
import UserService from '@/services/UserService'
import { useRoute, useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const formRef = ref<FormInst | null>(null)
const phone = ref(null)
const errorMessage = ref<string | null>(null)
const loading = ref(false)

const model = ref({
  phone: '',
})

const rules: FormRules = {
  phone: {
    required: true,
    validator() {
      // custom handling since vue-tel-input is not an naive-ui form component
      if (!(phone.value as any)?.phoneObject.valid) {
        return new Error(t('savePhone.validPhoneRequired'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

const savePhone = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      const number = (phone.value as any)?.phoneObject.number
      UserService.savePhone(number)
        .then(() => {
          const redirect = route.query.redirect as string
          router.push({ name: 'verifyPhone', query: { redirect } })
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('savePhone.saveFailed')
          loading.value = false
        })
    })
    .catch(() => {})
}
</script>
