import {
  createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
} from 'vue-router'
import BuyCreditsView from '@/views/BuyCreditsView.vue'
import ContentView from '@/views/ContentView.vue'
import DashboardEditTeamView from '@/views/dashboard/DashboardEditTeamView.vue'
import DashboardProfileView from '@/views/dashboard/DashboardProfileView.vue'
import DashboardTeamsView from '@/views/dashboard/DashboardTeamsView.vue'
import DashboardTeamUsersView from '@/views/dashboard/DashboardTeamUsersView.vue'
import ForgotPasswordView from '@/views/ForgotPasswordView.vue'
import HomeView from '@/views/HomeView.vue'
import LoginView from '@/views/LoginView.vue'
import ResetPasswordView from '@/views/ResetPasswordView.vue'
import SavePhoneView from '@/views/SavePhoneView.vue'
import StartView from '@/views/StartView.vue'
import StartConfirmView from '@/views/StartConfirmView.vue'

export function createRouter() {
  return _createRouter({
    history: import.meta.env.SSR
      ? createMemoryHistory(import.meta.env.BASE_URL)
      : createWebHistory(import.meta.env.BASE_URL),
    routes: [
      {
        path: '/:locale(.{2})?',
        children: [
          {
            path: '',
            name: 'home',
            component: HomeView,
          },
          {
            path: 'buy',
            name: 'buy',
            component: BuyCreditsView,
          },
          {
            path: 'content/:key',
            name: 'content',
            component: ContentView,
          },
          {
            path: 'dashboard',
            meta: { requiresAuth: true, ssr: false },
            children: [
              {
                path: 'profile',
                name: 'dashboard.profile',
                component: DashboardProfileView,
              },
              {
                path: 'teams',
                name: 'dashboard.teams',
                component: DashboardTeamsView,
                meta: { requiresAuth: true },
              },
              {
                path: 'teams/new',
                name: 'dashboard.teams.new',
                component: DashboardEditTeamView,
              },
              {
                path: 'teams/:id',
                name: 'dashboard.teams.edit',
                component: DashboardEditTeamView,
              },
              {
                path: 'teams/:id/users',
                name: 'dashboard.teams.users',
                component: DashboardTeamUsersView,
              },
            ],
          },
          {
            path: 'forgot-password',
            name: 'forgot-password',
            component: ForgotPasswordView,
          },
          {
            path: 'games/adventure',
            name: 'adventure-game',
            component: () => import('@/views/games/GameView.vue'),
          },
          {
            path: 'info',
            children: [
              {
                path: 'audio',
                name: 'info.audio',
                component: HomeView,
              },
              {
                path: 'audio',
                children: [
                  {
                    path: 'voice',
                    name: 'info.audio.voiceGenerator',
                    component: () =>
                      import('@/views/info/VoiceGeneratorView.vue'),
                  },
                ],
              },
              {
                path: 'image',
                name: 'info.image',
                component: HomeView,
              },
              {
                path: 'image',
                children: [
                  {
                    path: 'image',
                    name: 'info.image.generator',
                    component: () =>
                      import('@/views/info/ImageGeneratorView.vue'),
                  },
                  {
                    path: 'image-background',
                    name: 'info.image.image-background',
                    component: () =>
                      import('@/views/info/ImageBackgroundView.vue'),
                  },
                  {
                    path: 'image-to-image',
                    name: 'info.image.image-to-image',
                    component: () =>
                      import('@/views/info/ImageToImageView.vue'),
                  },
                  {
                    path: 'upscaling',
                    name: 'info.image.upscaler',
                    component: () => import('@/views/info/UpscalerView.vue'),
                  },
                ],
              },
              {
                path: 'text',
                name: 'info.text',
                component: HomeView,
              },
              {
                path: 'text',
                children: [
                  {
                    path: 'chat',
                    name: 'info.text.chat',
                    component: () =>
                      import('@/views/info/ChatCompletionView.vue'),
                  },
                  {
                    path: 'summary',
                    name: 'info.text.summarizer',
                    component: () => import('@/views/info/SummarizerView.vue'),
                  },
                  {
                    path: 'text',
                    name: 'info.text.tool',
                    component: () => import('@/views/info/TextToolView.vue'),
                  },
                  {
                    path: 'translation',
                    name: 'info.text.translator',
                    component: () => import('@/views/info/TranslatorView.vue'),
                  },
                  {
                    path: 'talk-to',
                    name: 'info.text.talkTo',
                    component: () => import('@/views/info/TalkToView.vue'),
                  },
                ],
              },
            ],
          },
          {
            path: 'login',
            name: 'login',
            meta: { ssr: false },
            component: LoginView,
          },
          {
            path: 'payment/:package',
            name: 'payment',
            component: () => import('@/views/PaymentView.vue'),
            meta: { requiresAuth: true, ssr: false },
          },
          {
            path: 'payment/:package/status',
            name: 'payment-status',
            component: () => import('@/views/PaymentStatusView.vue'),
            meta: { requiresAuth: true, ssr: false },
          },
          {
            path: 'redeem-coupon',
            name: 'redeemCoupon',
            component: () => import('@/views/RedeemCouponView.vue'),
            meta: {
              requiresAuth: true,
              requiresVerifiedPhone: true,
              ssr: false,
            },
          },
          {
            path: 'reset-password',
            name: 'reset-password',
            component: ResetPasswordView,
          },
          {
            path: 'save-phone',
            name: 'savePhone',
            component: SavePhoneView,
            meta: { requiresAuth: true, ssr: false },
          },
          {
            path: 'start',
            name: 'start',
            component: StartView,
          },
          {
            path: 'start-confirm',
            name: 'start-confirm',
            component: StartConfirmView,
          },
          {
            path: 'survey',
            name: 'survey',
            meta: { ssr: false },
            component: () => import('@/views/SurveyView.vue'),
          },
          {
            path: 'survey/:id',
            name: 'survey',
            meta: { ssr: false },
            component: () => import('@/views/SurveyView.vue'),
          },
          {
            path: 'team-invite/:id',
            name: 'team-invite',
            meta: { requiresAuth: true, ssr: false },
            component: () => import('@/views/TeamInviteView.vue'),
          },
          {
            path: 'tools',
            children: [
              {
                path: 'chat/:conversationId?',
                name: 'chat',
                component: () => import('@/views/tools/ChatCompletionView.vue'),
                meta: { showLibrary: true },
              },
              {
                path: 'image',
                name: 'imageGenerator',
                component: () => import('@/views/tools/ImageGeneratorView.vue'),
              },
              {
                path: 'image-background',
                name: 'image-background',
                component: () =>
                  import('@/views/tools/ImageBackgroundView.vue'),
              },
              {
                path: 'image-to-image',
                name: 'image-to-image',
                component: () => import('@/views/tools/ImageToImageView.vue'),
              },
              {
                path: 'infinite-loop',
                name: 'infiniteLoop',
                component: () => import('@/views/tools/InfiniteLoop.vue'),
              },
              {
                path: 'summary',
                name: 'summarizer',
                component: () => import('@/views/tools/SummarizerView.vue'),
              },
              {
                path: 'talk-to',
                name: 'talkTo',
                component: () => import('@/views/tools/TalkToView.vue'),
              },
              {
                path: 'text',
                name: 'textTool',
                component: () => import('@/views/tools/TextToolView.vue'),
              },
              {
                path: 'translation',
                name: 'translator',
                component: () => import('@/views/tools/TranslatorView.vue'),
              },
              {
                path: 'upscale',
                name: 'upscaler',
                component: () => import('@/views/tools/UpscalerView.vue'),
              },
              {
                path: 'voice',
                name: 'voiceGenerator',
                component: () => import('@/views/tools/VoiceGeneratorView.vue'),
              },
            ],
            meta: { requiresAuth: true, ssr: false },
          },
          {
            path: 'verify-phone',
            name: 'verifyPhone',
            component: () => import('@/views/VerifyPhoneView.vue'),
            meta: { requiresAuth: true, ssr: false },
          },
          {
            path: 'waitlist',
            name: 'waitlist',
            component: () => import('@/views/WaitlistView.vue'),
          },
          {
            path: 'waitlist/confirm',
            name: 'waitlistConfirm',
            component: () => import('@/views/WaitlistConfirmView.vue'),
          },
          {
            path: 'not-found',
            name: 'notFound',
            component: () => import('@/views/NotFoundView.vue'),
          },
          {
            path: ':pathMatch(.*)*',
            component: () => import('@/views/NotFoundView.vue'),
          },
        ],
      },
    ],
  })
}
