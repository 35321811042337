<template>
  <div class="p-4 md:p-16">
    <n-card :embedded="true">
      <h1 class="text-center">{{ t('login.title') }}</h1>
      <n-form
        ref="formRef"
        label-placement="left"
        label-width="auto"
        :model="model"
        :rules="rules"
        size="large"
        @keyup.enter="login"
      >
        <n-form-item :label="t('login.email')" path="email">
          <n-input ref="email" v-model:value="model.email" />
        </n-form-item>
        <n-form-item :label="t('login.password')" path="password">
          <n-input
            minlength="8"
            type="password"
            v-model:value="model.password"
          />
        </n-form-item>
        <div class="text-right">
          <RouterLink :to="{ name: 'forgot-password' }">
            {{
              t('login.forgotPassword')
            }}
          </RouterLink>
        </div>
        <n-form-item label=" " class="mt-4">
          <n-button type="primary" @click="login" :disabled="loading">
            <n-spin v-if="loading" size="small" stroke="white" />
            <span v-if="!loading">{{ t('login.login') }}</span>
          </n-button>
          <RouterLink
            :to="{ name: 'start', query: { redirect: route.query.redirect } }"
            class="ml-8"
          >
            {{ t('login.register') }}
          </RouterLink>
        </n-form-item>
        <p v-if="errorMessage" class="text-red-500">
          {{ errorMessage }}
        </p>
      </n-form>
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, onMounted, ref } from 'vue'
import { NButton, NCard, NForm, NFormItem, NInput, NSpin } from 'naive-ui'
import type { FormInst, FormItemRule, FormRules } from 'naive-ui'
import { isEmail } from '@/utils/is-email'
import UserService from '@/services/UserService'
import { useRoute, useRouter } from 'vue-router'
import { useMeta } from '@/composables/Meta'

const { t } = useI18n()
const router = useRouter()
const route = useRoute()

const emailRef = ref<HTMLInputElement | null>(null)
const errorMessage = ref<string | null>(null)
const formRef = ref<FormInst | null>(null)
const loading = ref(false)

const { updateMetaData } = useMeta()

updateMetaData({
  title: computed(() => t('login.seoTitle')),
  description: computed(() => t('login.seoDescription')),
})

const model = ref({
  email: '',
  password: '',
})

const rules: FormRules = {
  email: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('login.emailRequired'))
      } else if (!isEmail(value)) {
        return new Error(t('login.emailInvalid'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
  password: {
    required: true,
    validator(rule: FormItemRule, value: string) {
      if (!value) {
        return new Error(t('login.passwordRequired'))
      } else if (value.length < 8) {
        return new Error(t('login.passwordTooShort'))
      }
      return true
    },
    trigger: ['blur', 'input'],
  },
}

onMounted(() => {
  emailRef.value?.focus()
})

const login = () => {
  errorMessage.value = null
  formRef.value
    ?.validate(async (errors) => {
      if (errors) {
        return
      }

      loading.value = true
      await UserService.login(model.value.email, model.value.password)
        .then(() => {
          const redirect = route.query.redirect as string
          if (redirect) {
            router.push(redirect)
          } else {
            router.push({ name: 'home' })
          }
        })
        .catch((error) => {
          errorMessage.value =
            error?.response?.data?.message ?? t('login.loginFailed')
          loading.value = false
        })
        .catch(() => {})
    })
    .catch(() => {})
}
</script>
