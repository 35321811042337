<template>
  <div class="p-4 md:p-16">
    <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
    <n-card v-if="content" :embedded="true" class="p-4 md:p-8">
      <h1>{{ content.title }}</h1>
      <div @click="containerClick" v-html="content.content" />
    </n-card>
  </div>
</template>

<script setup lang="ts">
import { NCard } from 'naive-ui'
import { useRoute, useRouter } from 'vue-router'
import { computed, ref, watch } from 'vue'
import ContentService from '@/services/ContentService'
import type Content from '@/models/Content'
import { useMeta } from '@/composables/Meta'

const { updateMetaData } = useMeta()
const route = useRoute()
const router = useRouter()

const content = ref<Content | null>(null)

const key = computed((): string => {
  return route.params.key as string
})

const containerClick = (e: any) => {
  const baseUrl = window.location.protocol + '//' + window.location.host
  if (e.target.tagName === 'A' && e.target.href.startsWith(baseUrl)) {
    e.preventDefault()
    const url = e.target.href.substring(baseUrl.length)
    router.push(url)
  }
}

watch(
  key,
  () => {
    if (!key.value) return
    ContentService.getContent(key.value).then((res) => {
      if (!res) {
        router.replace({ name: 'notFound' })
      }
      content.value = res
      if (content.value) {
        updateMetaData({
          title: computed(() => content.value?.title + ' | KI Toolbox'),
          description: computed(() => content.value?.description),
          image: computed(() => content.value?.image_url),
        })
      }
    })
  },
  { immediate: true }
)
</script>
